<template>
    <div>
        <van-nav-bar
            title="储能电力解决方案"
            left-arrow
            @click-left="onClickLeft"
        />
        <form ref="saveForm"  @submit.prevent="submit">
        <div class="basicDiv">
                <div class="lineDiv">
                    <span>储电设备方案</span>
                </div>
                <!-- <div class="smallLineDiv">
                    <p>原始单价</p>
                    <input type="text" placeholder="请输入" name="originalPrice" >
                </div> -->
                <div class="smallLineDiv">
                    <p>所属行业别</p>
                    <select name="industry" >
                        <option v-for="(item,index) in map_industry" :key="index" :value="item.val">{{item.label}}</option>
                    </select>
                </div>
                <div class="smallLineDiv">
                    <p>用电时段</p>
                    <div v-for="(item,index) in time_interval" :key="index" >
                        <input type="checkbox" :value="item.val" :id="item.id"  v-model='stageNumberArr' name='stageNumber' />
                        <label :for="item.id" class="labelText">{{item.label}}</label>
                    </div>
                </div>
                <!-- <div class="smallLineDiv">
                    <p>平均电费(元)</p>
                    <input type="text" placeholder="请输入" name="electricityFees" >
                </div> -->
                <div v-for="(item,index) in powerArr" :key="index" >
                            <div class="titleDelectDiv">
                                <span class="title">变压器KVA({{index+1}})</span>
                                <div class="delect" @click="toDelectPower(index)">删除</div>
                            </div>
                           
                            <div class="smallLineDiv">
                                <p>变压器(KVA)</p>
                                <input type="text" placeholder="请输入" :name="transformerKva(index)" >
                            </div>       
                            
                </div>
                             <div class="addDiv" @click="toAddPower">
                                <img src="../../assets/image/powerMeasure/add.png" alt="">
                                <p>增加变压器</p>
                            </div>
             
                <div class="smallLineDiv">
                    <p>变压器使用率(%)</p>
                    <input type="text" placeholder="请输入" name="transformerUtilization" >
                </div>
                <div class="smallLineDiv">
                    <p>变压器容量费(元)</p>
                    <input type="text" placeholder="请输入" name="electricityCapacity" >
                </div>
                <div class="smallLineDiv">
                    <p>场地</p>
                    <div>
                        <input type="radio" value="1" name="space" id="space_1"  />
                        <label for="space_1" >室内</label>
                        <input type="radio" value="2" name="space" id="space_2" />
                        <label for="space_2" >室外</label>
                    
                    </div>
                    
                </div>
                <div class="smallLineDiv">
                    <p>营业需求</p>
                    <input type="text" placeholder="请输入" name="programmeInfo" >
                </div>  
        </div>
        <div class="basicDiv">
            <div class="smallLineDiv">
                <p>备注</p>
                <input type="text" placeholder="请输入" name="remarkInfo" >
            </div>
        </div >
        <button class="toSure" type="submit">确定</button>
    </form>
         <van-overlay :show="bPlanShow">
                <div class="wrapper" >
                   <div class="block_a">
                             <p class="saveProportion_a">效益比</p> 
                                <p class="saveNum">{{data.programEfficiency}}</p> 
                                <p class="newText_a ">省多少(元/月)</p>
                                <p class="newMoney_a ">¥ {{data.saveCost ?  (data.saveCost.toFixed(0) * 1).toLocaleString(): data.saveCost}}</p>
                                <img src="../../assets/image/powerMeasure/cancel.png" class="cancel"  @click="toHiddenApply"> 
                                <div class="applyDiv_b">
                                     <button class="toApply" @click.stop @click="toApply()">正式申请</button>   
                                </div>
                                <p class="explainText">* 上述效益比仅供参考,正式申请后会有专属技术</p>
                                <p class="explainText">顾问为您计算最终效益比。</p>
                    </div>
                </div>
            </van-overlay>
    </div>
</template>
<script>
import {getLabels,saveEnclosurePlanB} from '../../network/addMes'
    export default{
        name:'electricityBplan',
        data(){
            return{
                bPlanShow:false,
                time_interval:[],
                map_industry:[],
                powerArr:[{transformerKva:'',transformerUtilization:''}],
                data:{},
                isToNest:false, //可进行下一步
                stageNumberArr:[]
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back();
            },
            toAddPower(){
                this.powerArr.push({})
            },
            toDelectPower(index){
                this.powerArr.splice(index,1);
            },
            transformerUtilization(index){
                return 'electricTransformer['+index+'].transformerUtilization'
            },
            transformerKva(index){
                return 'electricTransformer['+index+'].transformerKva'
            },
            submit(e){   
                let request = {};
              

                const formData = new FormData(e.target);
                for (let [key, value] of formData) {
                    request[key] = value
                 }
                request['assessId'] = this.$route.query.id;
                request['stageNumber'] = this.stageNumberArr;
                if(request['electricTransformer[0].transformerKva'] == null || request['electricTransformer[0].transformerKva'] == 0){
                    this.$toast('请至少填写一个变压器!')
                    return;
                }
                this.$toast.loading({
                        message: '努力测算中...',
                        forbidClick: false,
                        duration:0
                });
                 saveEnclosurePlanB(request).then((res)=>{
                    this.$toast.clear();
                    if(res.success){
                        this.bPlanShow = true;
                        this.data = res.data.discount_scheme;
                        this.isToNest = res.data.type
                    }
                 })
            },
            toHiddenApply(){
                this.bPlanShow = false;
            },
            toApply(){
                if(this.isToNest){
                     this.$router.push({
                        path: "/powerMeasure/basicMessage",
                        query:{
                            id:this.$route.query.id
                        }
                     });
                }else{
                    this.$toast('请重新测算,缺乏重要指标参数')
                }
              
            }
        },
        created(){
            getLabels('time_interval').then((res)=>{
                if(res.success){
                    this.time_interval = res.data
                }
            }),
            getLabels('map_industry').then((res)=>{
                if(res.success){
                    this.map_industry = res.data
                }
            })
        }
        
    }
</script>
<style scoped>
.titleDelectDiv{
    height: 70px;
    position: relative;
    background: #ECF6F6;
}
.delect{
    width: 83px;
    line-height: 40px;
    border: 1px solid #01A4A4;
    border-radius: 8px;
    text-align: center;

    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    position: absolute;
    top: 15px;
    right: 24px;
}
.title{
    
    line-height: 70px;
    font-size: 25px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #01A4A4;
    margin: 0px 47px;
}
.basicDiv{
    background: #ffffff;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.lineDiv{
    display: flex;
    align-items: center;
}
.smallLineDiv{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.smallLineDiv input{
    text-align: right;
}
.smallLineDiv p{
  line-height: 80px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #282828;
}

.lineDiv span{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 33px 10px 26px 65px;
}
.smallLineDiv{
    width: 82%;
    margin: auto;
    border-top: solid 2px #F4F4F5;
}
.addDiv{
    border-top: solid 1px #EEF0FC;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}
.addDiv img{
    width: 41px;
    height: 41px;
    margin-right: 12px;
}
.addDiv p{
    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #01A4A4;
    line-height: 130px;
}
.toSure{
    width: 88%;
    line-height: 80px;
    background: #06B2B2;
    border-radius: 40px;
   text-align: center;
    font-size: 34px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin: 50px auto;
    display: block;
}
.wrapper {
    display: flex;
    align-items: center;
   /*  justify-content: center; */
    height: 100%;
    flex-direction: column;
  }
  .block_a {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 670px;
    height: 900px;
    background-image: url('../../assets/image/powerMeasure/plan_b.png');
    background-size: cover;
    position: relative;
    margin-top: 160px;
  }

.saveNum{
  margin-top: 60px;
    font-size: 80px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFF000;
    line-height: 36px;
}
.saveProportion_a{
    margin-top: 320px;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 36px;
}


.newText_a{
    margin-top: 65px;
    font-size: 40px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    
}

.newMoney_a{
    margin-top: 50px;
    font-size: 80px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFF000;
    line-height: 36px;
    
}
.applyDiv_b{
    display: flex;
    justify-content: space-around;
    width: 70%;
    text-align: center;
    margin-top: 95px;
    margin-bottom: 50px;
}
.toApply{
 
    width: 480px;
    background: linear-gradient(180deg, #13D8D6, #0EA6A6, #139978);
    border-radius: 32px;
    font-size: 37px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 63px;
    text-shadow: 0px 4px 0px rgba(8,113,99,0.93);
}
.cancel{
    width: 50px;
    height: 50px;
    position: absolute;
    right: 15px;
    top: 150px;
}
.explainText{

    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #DDDDDD;
    line-height: 40px;
}
</style>
